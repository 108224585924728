<template>
    <div>
        <div class='text-left'>
            <h1 class="users-title"><span class="arrow arrow--left" @click='$router.go(-1)'></span>{{ $t("admin.inviteAuthor") }}</h1>
            <b-alert :show="alert.dismissCountDown" fade id="alert"
                    @dismiss-count-down="countDownChanged"
                    class="alert-success">{{alert.notification}}
            </b-alert>
            <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                    @dismiss-count-down="countDownChangedDanger"
                    class="alert-danger">{{alertDanger.notification}}
                    <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
            </b-alert>
            <form class="form d-flex flex-column" style="gap: 30px" @submit.prevent="showConfirmationModal" autocomplete="off" novalidate>
                <TrekioInput id="email" trim v-model="form.email" :label="$t('inputLabels.authorEmail')" :error="error.email"/>
                <TrekioSelect v-model="form.language" :label="$t('inputLabels.language')" :text="form.language">
                    <TrekioSelectOption v-for="language in $store.state.publicLanguageCodes" :key="language" :value="language">{{ language }}</TrekioSelectOption>
                </TrekioSelect>
                <div class="buttons-container d-flex">
                    <TrekioButton @click="showConfirmationModal" type="button" primary> {{ $t("buttons.sendInvitation") }}</TrekioButton>
                </div>
            </form>
            <h2 class="mt-5 mb-3">Pozvaní autoři</h2>
            <p v-if="$apollo.queries.tokens.loading">Načítání...</p>
            <table v-else>
                <tr>
                    <th>Email</th>
                    <th>Platné do</th>
                </tr>
                <tr v-for="token in sortedTokens" :key="token.id">
                    <td>{{ token.email }}</td>
                    <td>{{ formatDate(token.expirationDate) }}</td>
                </tr>
            </table>
            <b-modal class="modal" id="invitation-confirm-modal" hide-footer hide-header no-close-on-backdrop>
                <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                    <h3 class="moda-title pb-3">{{ $t("modals.invitationTitle", [form.email]) }}</h3>
                    <p  class="moda-text pb-5">{{ $t("modals.invitationText") }}</p>
                    <div class="pb-5 d-flex" style="gap: 40px">
                        <TrekioButton secondary :disabled="loading != ''"  @click="$bvModal.hide('invitation-confirm-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton primary :isLoading="loading != ''" :loadingText="loading" @click="onSubmit">{{ $t("buttons.send") }}</TrekioButton>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import UserService from "@/services/UserService";
    import sharedUtils from "../../utils/sharedUtils";
    import { TOKENS } from '@/api/graphql/query/TokenQuery'

    export default {
        name: "InviteAuthorPage",

        data: function() {
            return {
                initialLoading: true,
                loading: '',
                form: {
                    email:'',
                    language: this.$store.state.preferredLanguage,
                },
                error: {
                    email: '',
                },
                dismissSecs: 10,
                dismissCountDown: 0,
                alert: {
                    dismissSecs: 6,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
              }
            }
        },

        computed: {
            sortedTokens() {
                if (!this.tokens) return
                return [...this.tokens].sort((a, b) => {
                    return new Date(b.expirationDate) - new Date(a.expirationDate)
                })
            }
        },

        methods: {
            formatDate(date) {
                const moment = require('moment')
                return moment(date).format('D. M. YYYY HH:mm')

            },
            showConfirmationModal() {
                if (!this.validateInputs()) return
                this.$bvModal.show('invitation-confirm-modal')
            },
            validateInputs() {
                if (!this.form.email) {
                    this.error.email = this.$t("inputErrors.required")
                    return false
                }
                else {
                    const isEmailValid = sharedUtils.isEmailValid(this.form.email)
                    this.error.email = !isEmailValid ? this.$t("inputErrors.incorrectEmailFormat") : ''
                    return isEmailValid
                }
            },

            onSubmit: async function() {
                this.loading = this.$t("loading.authorInvite")
                console.log("OnSubmit form");
                await UserService.sendInvitationEmail(this.form.email, this.form.language)
                    .then((resp) => {
                        if (resp.data.sendInvitationEmail.includes("Error")) {
                            this.showAlertDanger(this.$t('alerts.emailSendError'))
                        } else {
                            this.showAlert(this.$t('alerts.emailSend'))
                            this.form.email = ''
                            this.$apollo.queries.tokens.refetch()
                        }
                    })
                    .catch((err) => {
                        this.showAlertDanger("Chyba při odesílání emailu.")
                        console.log(err)
                    })
                this.$bvModal.hide('invitation-confirm-modal')
            },

            showAlert(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alert.dismissCountDown = this.alert.dismissSecs
                this.alert.notification = notification;
            },

            showAlertDanger(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
                this.alertDanger.notification = notification
            },
            countDownChanged(dismissCountDown) {
                this.alert.dismissCountDown = dismissCountDown
            },

            countDownChangedDanger(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown
            },
        },

        apollo: {
            tokens: {
                query: TOKENS,
                variables: function() {
                    return {
                        tokenType: "INVITATION"
                    } 
                },
                fetchPolicy: 'network-only'
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    table {
        tr {
            background-color: $tr-white-alt;
        }
        th {
            font-weight: 600;
        }
        th, td {
            border: lightgray 1px solid;
            padding: 10px 20px;
        }

        td:first-child {
            word-break: break-word;
        }
        td:nth-child(2) {
            white-space: nowrap;
        }
    }

</style>